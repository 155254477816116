<template>
  <div>
    <Headline v-if="items.length" class="my-4 text-center" :title="title" />
    <div :class="classes" class="mx-4 mb-4 gap-4">
      <div
        v-for="(item, index) in cards"
        :key="index"
        :class="{
          'hidden sm:inline': limitMobile && index >= limitMobile,
          'sm:hidden': limitValue && index >= limitValue,
        }"
      >
        <slot :id="index" :item="item" />
      </div>
    </div>
    <CardSlider
      v-slot="{ item, id }"
      :class="{
        'mb-4 md:hidden': mobileSlider,
        hidden: !mobileSlider,
      }"
      :items="items"
      :settings="settings"
    >
      <slot :id="id" :item="item" />
    </CardSlider>
    <div
      v-if="button && limitValue < items.length"
      class="my-4 hidden justify-center md:flex"
    >
      <G7Button color="orange" :label="button" @click="limitValue += steps" />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  items: Array<unknown>;
  title?: string;
  limit?: number | null;
  limitMobile?: number | null;
  mobileSlider?: boolean;
  button?: string | null;
  cols?: "3" | "4";
  steps?: number;
  settings?: Record<string, string | number | boolean | unknown>;
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [] as unknown[],
  title: "",
  limit: null,
  limitMobile: null,
  mobileSlider: false,
  button: null,
  cols: "3",
  steps: 9,
  settings: () => {
    return {};
  },
});

const limitValue = ref(props.limit);

const cards = computed(() => {
  if (props.limit === null) {
    return props.items;
  } else {
    return props.items.filter((_, index) => limitValue.value > index);
  }
});

const colsClasses = {
  "3": "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3",
  "4": "grid-cols-1 sm:grid-cols-2 lg:grid-cols-4",
};

const classes = computed(() => {
  const style = props.mobileSlider ? "hidden md:grid" : "grid";

  return `${style} ${colsClasses[props.cols]}`;
});
</script>

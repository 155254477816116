<template>
  <VueCarousel v-if="show">
    <Carousel :settings="settings" class="overflow-hidden">
      <Slide
        v-for="(item, index) in items"
        :key="index"
        class="flex w-full items-center justify-center"
      >
        <slot :id="index" :item="item" />
      </Slide>
      <template #addons>
        <navigation>
          <template #next>
            <span class="mr-9">
              <IconsUp class="size-10 rotate-90 fill-g7-blue" />
            </span>
          </template>
          <template #prev>
            <span class="ml-9">
              <IconsUp class="size-10 -rotate-90 fill-g7-blue" />
            </span>
          </template>
        </navigation>
      </template>
    </Carousel>
  </VueCarousel>
</template>
<script lang="ts" setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";

const props = defineProps<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>;
  settings?: Record<string, string | number | boolean | unknown>;
}>();

const show = computed(() => {
  return props.items.length > 0;
});

const settings = {
  wrapAround: props.items.length > 1,
  ...(props.settings || {}),
};
</script>
